import { Fragment } from 'react';
import { Link } from 'react-router-dom';

function Privacy() {
  return (
    <Fragment>
      <header>
        <h1 id="tietosuoja">Sensotrendin yleinen tietosuoja&shy;seloste</h1>
        <small>Päivitetty 5.8.2023</small>
      </header>
      <main id="eula">
        <section id="seuranta">
          <h2>Emme seuraa sinua</h2>
          <p>
            Emme tallenna mitään henkilötietojasi emmekä pyri seuraamaan sinun toimintaasi tällä
            sivulla tai muualla internetissä, emmekä pyri yhdistelemään tietojasi eri lähteistä
            sinun tietämättäsi.
          </p>
        </section>
        <section id="markkinointi">
          <h2>Vapaaehtoinen markkinointilupa</h2>
          <p>
            Mikäli haluat, kerromme mielellämme palvelumme kehityksen etenemisestä. Teemme sen
            mieluiten sähköpostitse. Voit kertoa meille sähköpostiosoitteesi tätä tarkoitusta
            varten <Link to="/feedback">yhteydenottolomakkeen</Link> avulla.
          </p>
          <p>
            Emme luovuta sähköpostiosoitettasi kenellekään muulle. Käytämme sähköpostiasi vain
            kertoaksemme sinulle palveluidemme kehityksen edistymisestä sekä mahdollisuuksista
            osallistua kehitykseen. Emme käytä sähköpostiasi muuhun tarkoitukseen.
          </p>
        </section>
        <section id="palvelut">
          <h2>Palveluiden tietosuojaselosteet</h2>
          <p>
            Eri palvelumme tarvitsevat toimiakseen tietoja, myös henkilötietojasi käyttäessäsi
            niitä.
          </p>
          <p>
            Monet palveluistamme antavat sinun käyttää sähköpostiosoitetta käyttäjätunnuksena.
            Palvelut myös vahvistavat sähköpostiosoitteen toiminnan palveluun kirjautumisen
            yhteydessä. Toimivan yhteyden varmistaminen on meille tärkeää, jotta tiedämme, että
            voimme tavoittaa sinut tarvittaessa, esimerkiksi ilmoittaaksemme mahdollisesta
            palveluiden käyttöön liittyvästä haittatilanteesta.
          </p>
          <p>
            Kysymme myös palveluidemme käyttöönoton yhteydessä markkinointiluvan, voidaksemme
            kertoa palveluidemme kehityksestä aiheesta kiinnostuneille. Voit joko antaa
            markkinointiluvan tai olla antamatta sitä, se ei vaikuta millään tavalla palveluiden
            muuhun käyttöön.
          </p>
          <p>
            Kerromme tietojen käytöstä tarkemmin erillisessä palveluitamme koskevassa <a
            href="/connect/privacy">tietosuojaselosteessa</a> sekä <a
            href="/connect/eula">käyttöehdoissa.</a>
          </p>
        </section>
        <section id="yhteystiedot">
          <h2>Yhteystiedot</h2>
          <address>
            Sensotrend Oy<br />
            2606155-7<br />
            Tampellan esplanadi 19 A 55<br />
            FI-33180 Tampere
          </address>
          <p>
            Tietosuojavastaava Mikael Rinnetmäki, <a
            href="mailto:privacy@sensotrend.com">privacy@sensotrend.com</a>.
          </p>
        </section>
      </main>
    </Fragment>
  );
}

export default Privacy;
