import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import CallToAction from './CallToAction';
import hyvinvointitiedot from './hyvinvointitiedot.png';
import './index.css';

function Connect() {
  return (
    <Fragment>
      <h1 id="connect">Sensotrend Connect</h1>
      <p>
        Sensotrend Connect -palvelu auttaa siirtämään tietoja eri lähteistä Kanta-järjestelmän <a
        href="https://www.kanta.fi/hyvinvointitiedot">Omatietovarantoon</a> tai suoraan
        terveydenhuollon tietojärjestelmiin.
      </p>
      <figure>
        <a href={hyvinvointitiedot} title="Katso kuva suurempana">
          <img
            className="shadow"
            src={hyvinvointitiedot}
            alt="Ruutukaappaus hyvinvointitiedoista Omakannassa"
          />
        </a>
        <figcaption>Glukoosisensorin tietoja Omakannan Hyvinvointitiedot-osiossa.</figcaption>
      </figure>
      <CallToAction action="/connect" relative={false}>Aloita käyttö!</CallToAction>
      <blockquote>
        <p>
          Sensotrend automatisoi tiedonsiirron glukoosi&shy;sensoreista HUS:n raskausajan diabeteksen
          hoitoa tukevaan sovellukseen. Tämä helpottaa potilaiden elämää ja takaa laadukkaan ja
          nopean tiedon saannin <a
          href="https://www.cleverhealth.fi/fi/raskausdiabeteksen-etaseuranta">
          eMOM-tutkimus&shy;hankkeellemme</a>.
        </p>
        <footer>Saila Koivusalo, Naistentautien ja synnytysten erikoislääkäri, LT, dosentti, kehittämispäällikkö, HUS</footer>
      </blockquote>
      <h2 id="kehitys">Palvelu kehittyy jatkuvasti</h2>
      <p>
        Tällä hetkellä Sensotrend Connect -palvelu siirtää Omatitovarantoon <Link to="/uploader">Sensotrend
        Uploader</Link> -sovelluksen ja <a href="https://connect.nightscout.fi">Nightscout
        Connect</a> -palvelun tiedot.
      </p>
      <p>
        Tavoitteenamme on toteutus, jossa kaikki tiedot siirtyvät Omatietovarantoon
        automaattisesti laitevalmistajien pilvipalveluista. Tältä osin palvelumme on vielä
        kehitysvaiheessa.
      </p>
      <p>
        Välivaiheen toteutuksena työn alla on myös palvelu, jonka avulla joidenkin pilvipalveluiden
        tiedot voi siirtää palvelusta ladatun tiedoston avulla.
      </p>
      <p>
        Jos haluat osallistua palvelun kehitykseen tai testaukseen, <a
        href={`mailto:info@sensotrend.com?subject=${
          encodeURIComponent('Sensotrend Connect -kehitys')
        }&body=${encodeURIComponent(
        'Hei Sensotrend,\n\nTahtoisin osallistua Sensotrend Connect -palvelun kehitykseen ja testaukseen. Minua kiinnostaa erityisesti Dexcom Claity / Abbott LibreView / Medtronic CareLink -pilvipalvelu.\n'
        )}`}>olethan yhteydessä</a>!
      </p>
    </Fragment>
  );
}

export default Connect;
