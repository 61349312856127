import { Fragment } from 'react';

import CallToAction from './CallToAction';
import uploader from './uploader.jpg';
import './index.css';

function Uploader() {
  return (
    <Fragment>
      <h1 id="connect">Sensotrend Uploader</h1>
      <p>
        Sensotrend Uploader on tietokonesovellus, joka siirtää tiedot lukuisista
        verensokeri&shy;mittareista, glukoosi&shy;sensoreista ja insuliinipumpuista
        Kanta-järjestelmän <a
        href="https://www.kanta.fi/hyvinvointitiedot">Omatietovarantoon</a>.
      </p>
      <figure>
        <a href={uploader} title="Katso kuva suurempana">
          <img src={uploader} alt="Ruutukaappaus Sensotrend Uploader -sovelluksesta" />
        </a>
        <figcaption>Sensotrend Uploader -tietokonesovellus.</figcaption>
      </figure>
      <p>
        Sovelluksesta on ladattavissa <a href="/connect/download/Windows">Windows-</a>, <a
        href="/connect/download/Mac">Mac</a>- ja <a href="/connect/download/Linux">Linux
        </a>-versiot.
      </p>
      <CallToAction action="/connect" relative={false}>Aloita käyttö!</CallToAction>
      <blockquote>
        <p>
          Sensotrend provides us with the essential expertise in connecting data from medical
          devices of people with diabetes into our PHR system, and helps us integrate that
          information into the workflows of healthcare professionals.
        </p>
        <footer>Markus Gnägi, <a href="https://poolprax.ch">poolprax ag</a> Diabetes-Dossier</footer>
      </blockquote>
      <aside id="laitteet">
        <h2>Tuetut laitteet</h2>
        <section id="mittarit">
          <h4>Verensokeri&shy;mittarit</h4>
          <ul>
            <li>Accu-Chek Aviva Connect (Roche)</li>
            <li>Accu-Chek Guide (Roche)</li>
            <li>Accu-Chek Guide Me (Roche)</li>
            <li>Contour Next (Ascensia / Bayer)</li>
            <li>Contour Next Link (Ascensia)</li>
            <li>Contour Next Link 2.4 (Ascensia)</li>
            <li>Contour Next One (Ascensia)</li>
            <li>Contour Next USB (Ascensia)</li>
            <li>Contour USB (Ascensia / Bayer)</li>
            <li>CareSens Dual BLE (iSens)</li>
            <li>CareSens N POP (iSens)</li>
            <li>CareSens N Premier BLE (iSens)</li>
            <li>Freestyle Freedom Lite (Abbott)</li>
            <li>Freestyle Lite (Abbott)</li>
            <li>OneTouch Ultra 2 (Lifescan)</li>
            <li>OneTouch UltraMini (Lifescan)</li>
            <li>OneTouch Verio (Lifescan)</li>
            <li>OneTouch Verio Flex (Lifescan)</li>
            <li>OneTouch Verio IQ (Lifescan)</li>
            <li>Precision Xtra (Abbott)</li>
          </ul>
        </section>
        <section id="sensorit">
          <h4>Glukoosi&shy;sensorit</h4>
          <ul>
            <li>Dexcom G4</li>
            <li>Dexcom G5</li>
            <li>Dexcom G6</li>
            <li>Eversense (Senseonics)</li>
            <li>Freestyle Libre (Abbott)</li>
            <li>Freestyle Libre Pro (Abbott)</li>
          </ul>
        </section>
        <section id="pumput">
          <h4>Insuliini&shy;pumput</h4>
          <ul>
            <li>Animas Ping</li>
            <li>Animas Vibe</li>
            <li>Minimed 523 (Medtronic)</li>
            <li>Minimed 530G (Medtronic)</li>
            <li>Minimed 554 (Medtronic)</li>
            <li>Minimed 640G (Medtronic)</li>
            <li>Minimed 670G (Medtronic)</li>
            <li>Minimed 723 (Medtronic)</li>
            <li>Minimed 754 (Medtronic)</li>
            <li>Omnipod (Insulet)</li>
            <li>Omnipod DASH (Insulet)</li>
            <li>t:flex (Tandem)</li>
            <li>t:slim (Tandem)</li>
            <li>t:slim G4 (Tandem)</li>
            <li>t:slim X2 (Tandem)</li>
          </ul>
        </section>
        <p>
          Jos käyttämäsi laite ei vielä ole tuettu ja tahdot auttaa meitä tuen toteuttamisessa, <a
          href={`mailto:info@sensotrend.com?subject=${
            encodeURIComponent('Sensotrend Uploader')
          }&body=${encodeURIComponent(
            'Hei Sensotrend,\n\nTahtoisin auttaa laitetuen toteuttamisessa Sensotrend Uploaderiin. Käyttämäni laite on ...\n'
          )}`}>olethan yhteydessä</a>!
        </p>
      </aside>
    </Fragment>
  );
}

export default Uploader;
