import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import CallToAction from './CallToAction';
import ihmiset from './ihmiset.svg';
import jakaminen from './jakaminen.png';
import mittari from './mittari.svg';
import './index.css';
import './form.css';

function Frontpage() {
  return (
    <Fragment>
      <header>
        <img src={`${process.env.PUBLIC_URL}/sensotrend.svg`} alt="Sensotrend"/>
      </header>
      <main id="welcome">
        <section>
          <h1 id="omatietovaranto">Diabetes&shy;tiedot Omatieto&shy;varantoon</h1>
          <p>
            Sovellustemme avulla siirrät tietosi eri laitteista Kanta-järjestelmään kuuluvaan <a
            href="https://www.kanta.fi/hyvinvointitiedot">Omatietovarantoon</a>.
          </p>
          <img className="section" src={ihmiset} alt="" />
          <h3 id="connect">Sensotrend Connect</h3>
          <p>
            Sensotrend Connect -palvelu siirtää tiedot laitevalmistajien pilvipalveluista. <Link
            to="/connect">Lue lisää</Link>.
          </p>
          <h3 id="uploader">Sensotrend Uploader</h3>
          <p>
            Sensotrend Uploader on tietokonesovellus, joka siirtää tiedot lukuisista
            verensokeri&shy;mittareista, glukoosi&shy;sensoreista ja insuliinipumpuista. <Link
            to="/uploader">Lue lisää</Link>.
          </p>
          <CallToAction action="/connect" relative={false}>Aloita käyttö!</CallToAction>
        </section>
        <section>
          <h1>Tietojen tarkastelu</h1>
          <p>
            Kun tiedot ovat yhdessä paikassa, niiden tarkastelu helpottuu. Tiedot ovat myös
            halutessasi jaettavissa lääkärin, hoitajan tai vaikka vertaistuen kanssa.
          </p>
          <img className="section" src={mittari} alt="" />
          <h3 id="dashboard">Sensotrend Dashboard</h3>
          <p>
            Sensotrend Dashboard näyttää tietosi havainnollisessa muodossa.
          </p>
          <p>
            Voit halutessasi liittää palveluun hyvinvointi&shy;sovellusten tuottamia tietoja
            liikunnasta, ravinnosta ja levosta sekä stressistä.
          </p>
          <p>
            Kehitämme jatkuvasti uusia ja havainnollisempia näkymiä tietojen tarkasteluun. <a
            href="/dashboard">Lue lisää</a>.
          </p>
          <CallToAction action="/dashboard/signin" relative={false}>Kirjaudu sisään!</CallToAction>
        </section>
        <section>
          <h1>Avoimen lähdekoodin innovaatiot</h1>
          <p>
            Pyrimme tuomaan avoimen lähdekoodin kehittäjäyhteisön palveluita lähemmäs
            terveydenhuoltoa.
          </p>
          <h3>Nightscout Connect</h3>
          <p>
            Avoimen lähdekoodin kehittäjäyhteisö <a
            href="http://www.nightscout.info">Nightscout</a> on kehittänyt kymmeniä elämää
            helpottavia sovelluksia verensokerin etäseurantaan ja jopa automaattiseen insuliinin
            annosteluun.
          </p>
          <p>
            Toteuttamamme <a href="https://connect.nightscout.fi">Nightscout Connect</a> -palvelu
            mahdollistaa Nightscout-sovellusten yhdistämisen suoraan Kanta-järjestelmän
            Omatietovarantoon.
          </p>
          <p>
            Olemme mukana myös HUS:n <a
            href="https://www.cleverhealth.fi/fi/lapsidiabeetikko-ihan-sitra">kehityshankkeessa</a>,
            jossa Nightscout-tietoja käytetään diabeteksen hoidon osana Uudessa Lastensairaalassa.
          </p>
          <h3>Sensotrend Uploader</h3>
          <p>
            Sensotrend Uploader pohjautuu avoimena lähdekoodina kehitettyyn <a
            href="https://github.com/tidepool-org/uploader">Tidepool Uploader</a> -sovellukseen.
            Olemme varmistaneet sen toiminnan ja CE-merkinneet sen hyväksytyksi terveydenhuollon
            laitteeksi Euroopassa. Kehitämme sovellukseen myös tukea uusille laitteille ja pyrimme
            viemään nämä parannukset myös alkuperäiseen sovellukseen, laajemman yhteisön
            saataville.
          </p>
        </section>
        <section>
          <h1>Meihin voit luottaa</h1>
          <img className="section" src={jakaminen} alt="" />
          <p>
            Me kunnioitamme yksityisyyttäsi. Emme tallenna terveystietojasi omille palvelimillemme,
            vaan tiedot tallennetaan ainoastaan omatietovarantoon. Lue lisää <Link to="/privacy">
            tietosuojaselosteestamme</Link>.
          </p>
          <p>
            Sinä päätät, kuinka tietojasi käytetään ja kenen kanssa niitä jaetaan. Sensotrend on
            henkilötietojen reilua ja ihmisen valintaa kunnioittavaa käsittelyä edistävän <a
            href="https://mydata.org/">MyData Global</a> -organisaation perustajajäsen.
          </p>
          <p>
            Kaikki palvelumme on CE-merkitty lääkinnällisiksi laitteiksi, ja ne ovat läpäisseet
            Kanta-järjestelmään liittymiseen vaadittavat hyväksymistestit. Käytössämme on <Link
            to={`${process.env.PUBLIC_URL}/EUFI29-19003195-S.pdf`}> ISO 13485 -sertifioitu
            laatujärjestelmä</Link> terveydenhuollon laitteiden tuottamiseen.
          </p>
          <p>
            Sensotrendin työntekijöistä jokaisella on henkilökohtainen kokemus
            insuliininpuutos&shy;diabeteksesta, joko oman tai perheenjäsenensä diagnoosin kautta.
          </p>
        </section>
        <section>
          <h1>Ole yhteydessä!</h1>
          <p>
            Kehitämme palveluihimme jatkuvasti uusia ominaisuuksia. Tällä hetkellä työn alla ovat
            erityisesti automaattinen tiedonsiirto laitevalmistajien pilvipalveluista sekä
            visualisaatiot, jotka auttavat keskittymään ravinnon ja liikunnan vaikutuksiin.
          </p>
          <p>
            Otamme erittäin mielellämme vastaan kaikenlaista palautetta ja kehitysehdotuksia.
            Jokainen palaute auttaa meitä tekemään palveluistamme entistä parempia. Ole siis
            rohkeasti yhteydessä!
          </p>
          <p>
            Voit lähettää meille palautetta <Link to="/feedback">palautelomakkeen</Link> kautta.
            Meidät tavoittaa myös Facebookissa <a
            href="https://www.facebook.com/groups/547689758666580">Sensotrendin kanssakehittäjät
            </a>-ryhmästämme.
          </p>
        </section>
      </main>
    </Fragment>
  );
}

export default Frontpage;
