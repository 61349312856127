import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

function CallToAction(props) {
  const { action, autoFocus, children, relative = true, ...rest } = props;
  const ref = useRef(undefined);

  useEffect(() => {
    if (autoFocus) {
      ref.current.focus();
    }
  }, [autoFocus, ref]);

  return relative
  ? (
    <Link className="primary button" ref={ref} to={action} {...rest}>
      {children}
    </Link>
  )
  : (
    <a className="primary button" ref={ref} href={action} {...rest}>
      {children}
    </a>
  );
}

export default CallToAction;
