import { Fragment } from 'react';

import './index.css';
import './form.css';
import { Link } from 'react-router-dom';

function Register() {
  return (
    <Fragment>
      <header>
        <h1>Lisätietoja</h1>
      </header>
      <main id="register">
        <p>Kerromme mielellämme palveluidemme kehityksen edistymisestä kiinnostuneille.</p>
        <p>Lähetä meille sähköpostiosoitteesi, niin kerromme sinulle aika ajoin kuulumisia.</p>
        <p>
          Emme käytä sähköpostiosoitettasi mihinkään muuhun tarkoitukseen. Lue <Link to="/privacy"
          >tietosuojaselosteemme</Link>.
        </p>
        <form method="POST" action="/email/register">
          <label htmlFor="name" title="">
            Nimi
            <input id="name" type="text" title="" name="name" />
          </label>
          <label htmlFor="email" title="">
            Sähköposti
            <input id="email" type="email" title="" name="email" />
          </label>
          <input type="submit" value="Lähetä!" />
        </form>
      </main>
    </Fragment>
  );
}

export default Register;
