import React from 'react';
import { Link } from 'react-router-dom';


import facebook from './facebook.svg';
import instagram from './instagram.svg';
import twitter from './twitter.svg';
import linkedin from './linkedin.svg';
import github from './github.svg';

function Footer() {
  return (
    <footer>
      <div>
        <span>
          v{process.env.REACT_APP_VERSION}
        </span>
        <span>
          &copy;2013-2023 <a className="inline" href="/">Sensotrend&nbsp;Oy</a>
        </span>
      </div>
      <div>
        <span><Link className="inline" to="/privacy">Tietosuoja</Link></span>
        <span className="separator">&middot;</span>
        <span><Link className="inline" to="/feedback">Palaute</Link></span>
        <span className="separator">&middot;</span>
        <span><Link className="inline" to="/register">Lisätietoja</Link></span>
      </div>
      <div className="some">
        <a href="https://facebook.com/sensotrend"><img src={facebook} alt="Facebook" /></a>
        <a href="https://instagram.com/sensotrend"><img src={instagram} alt="Instagram" /></a>
        <a href="https://twitter.com/Sensotrend_FI"><img src={twitter} alt="Twitter" /></a>
        <a href="https://linkedin.com/company/sensotrend"><img src={linkedin} alt="LinkedIn" /></a>
        <a href="https://github.com/Sensotrend"><img src={github} alt="GitHub" /></a>
      </div>
    </footer>
  );
}

export default Footer;
