import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch, useLocation } from "react-router-dom";

import Connect from './Connect';
import Feedback from './Feedback';
import Footer from './Footer';
import Frontpage from './Frontpage';
import Privacy from './Privacy';
import Register from './Register';
import Uploader from './Uploader';

const base = process.env.PUBLIC_URL;
const supportsHistory = 'pushState' in window.history;

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function Routes() {
  return (
    <Router basename={base} forceRefresh={!supportsHistory}>
      <Switch>
        <Route path="/connect" component={Connect} />
        <Route path="/feedback" component={Feedback} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/register" component={Register} />
        <Route path="/uploader" component={Uploader} />
        <Route path="/" exact component={Frontpage} />
        <Redirect to="/" />
      </Switch>
      <Footer />
      <ScrollToTop />
    </Router >
  );
}

export default Routes;
