import { Fragment } from 'react';

import './index.css';
import './form.css';

function Feedback() {
  return (
    <Fragment>
      <header>
        <h1>Palaute</h1>
      </header>
      <main id="palaute">
        <p>
          Lähetä meille risuja, ruusuja, kehitystoiveita, yhteistyöehdotuksia tai mitä vain
          vinkkejä palveluidemme kehitykseen liittyen!
        </p>
        <p>
          Jos tahdot meidän palaavan asiaan, muistathan lisätä omat yhteystietosi palautteen
          osaksi.
        </p>
        <form method="POST" action="/email/feedback">
          <textarea name="feedback" />
          <input type="submit" value="Lähetä!" />
        </form>
      </main>
    </Fragment>
  );
}

export default Feedback;
